// React
import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";

// Material ui
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Chip,
  createStyles,
  Dialog,
  DialogContent,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  Toolbar,
  Typography
} from "@material-ui/core";

// Libs
import Scrollbars from "react-custom-scrollbars";
import { isAndroid } from "react-device-detect";
import Helmet from "react-helmet";

// Images
import icClose from "../../assets/ic-close.svg";
import logo from "../../assets/rf-e-learning-cac.png";

// Loader constant
import { CONST_LOADER } from "../../config/constantLoaders";

// Components
import Lesson from "../../features/Lesson/Lesson";
import SkeletonLoader from "../../features/SkeletonLoader/SkeletonLoader";
import Timer from "../../features/Timer/Timer";

// Helpers
import { getBackgroundTypeActivity } from "../../helpers/color-translator.helper";
import { formatterTypeActivity } from "../../helpers/text-formatter.helper";
// Models
import {
  Activity,
  ActivityPath,
  Lesson as LessonCAC,
  Type
} from "../../models/activity.model";

// Colors
import { colorCAC } from "../../styles/color";
import {
  BookmarkStatus,
  useActivityLazyQuery,
  QuizBaseFieldsFragment,
  LessonBaseFieldsFragment
} from "../../graphql";
import { QuizPlayer } from "../../features/Quiz/QuizPlayer";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store";
import { setCurrentModule, setSelectedPath } from "../../store/Module/actions";
import Bilan from "../../features/Quiz/Bilan/Bilan";
import {
  getLastBookmark,
  isLesson,
  isQuiz,
  mapActivity
} from "../../services/activity.service";
import { setErrorToHandleError } from "../../store/errorHandler/actions";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: "2001!important" as any
    },
    appBar: {
      backgroundColor: "#ffffff",
      [theme.breakpoints.up("md")]: {
        paddingTop: 20,
        paddingBottom: 20
      }
    },
    logo: {
      width: "200px"
    },
    headerTitle: {
      [theme.breakpoints.up("md")]: {
        textAlign: "center"
      },
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0.5)
      }
    },
    headerTitleName: {
      textOverflow: "ellipsis",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        whiteSpace: "nowrap",
        overflow: "hidden"
      }
    },
    chipDomain: {
      color: "white",
      [theme.breakpoints.up("md")]: {
        margin: theme.spacing(0.5)
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: 15
      }
    },
    button: {
      fontWeight: 600,
      textTransform: "none",
      color: "#000000",
      "&:hover": {
        backgroundColor: "#7ebc4e",
        color: "#FFFFFF"
      }
    },
    chip: {
      borderRadius: 20,
      width: 105,
      backgroundColor: "white"
    },
    secondToolbar: {
      backgroundColor: "#f9fdfc"
    },
    headerSecondToolbar: {
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
    },
    headerTitleResponsive: {
      whiteSpace: "nowrap"
    },
    activityContent: {
      backgroundColor: colorCAC.grayLight,
      padding: 16,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 8,
        paddingRight: 8
      }
    },
    btgroup: {
      height: 40,
      marginRight: 10
    },
    textUp: {
      fontSize: 20,
      lineHeight: "normal",
      fontWeight: 600,
      color: "#9ea2aa",
      right: 0,
      [theme.breakpoints.down("sm")]: {
        borderRadius: 0,
        borderBottom: "none",
        borderTop: "none"
      },
      [theme.breakpoints.up("md")]: {
        borderLeftWidth: 2,
        borderTopRightRadius: 25,
        borderBottomRightRadius: 25,
        backgroundColor: "white"
      }
    },
    textDown: {
      fontSize: 14,
      fontWeight: 600,
      color: colorCAC.black,
      [theme.breakpoints.down("sm")]: {
        borderRadius: 0,
        borderBottom: "none",
        borderTop: "none"
      },
      [theme.breakpoints.up("md")]: {
        borderRightWidth: 2,
        borderTopLeftRadius: 25,
        borderBottomLeftRadius: 25,
        backgroundColor: "white"
      }
    },
    container: {
      width: 960,
      maxWidth: "100%",
      minHeight: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.up("md")]: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 20,
        paddingBottom: 10
      }
    },
    containerVideo: {
      textAlign: "center",
      marginTop: 15
    },
    txtFinished: {
      color: colorCAC.greenRF
    },
    icFinished: {
      color: colorCAC.greenRF
    },
    icNotFinished: {
      color: "#eaeced",
      "&:hover": {
        color: "#eaeced"
      }
    }
  })
);

/**
 * Route props
 */
interface IRouteInfo {
  activityId: string;
  activityType: string;
}

interface IRouteActivityLessonOrQuiz extends RouteComponentProps<IRouteInfo> {}

/**
 * ActivityLessonOrQuiz Component
 */
const ActivityLessonOrQuiz = (props: IRouteActivityLessonOrQuiz) => {
  /** Props */
  const { match, history } = props;
  /** Classes  */
  const classes = useStyles();
  /** GraphQL */
  const [getActivity, { data }] = useActivityLazyQuery({
    errorPolicy: "all",
    fetchPolicy: "network-only"
  });
  /** Activity */
  const [activity, setActivity] = useState<Activity>();
  const [title, setTitle] = useState<string>("");
  /** Activity */
  const [quizGql, setQuizGql] = useState<QuizBaseFieldsFragment>();
  /** FontSize */
  const [fontSize, setFontSize] = useState(16);
  /** Loading state */
  const [loading, setLoading] = useState(true);
  /** Play timer */
  const [playTimer, setPlayTimer] = useState<boolean>(true);
  /** Next activity */
  const [nextActivity, setNextActivity] = useState<ActivityPath>();
  /** Show bilan */
  const [bilanScore, setBilanScore] = useState<number | null>(null);
  /** UseDispatch */
  const dispatch = useDispatch();

  const contentRef = useRef<any>(null);

  /** Is starter */
  const isStarter = match.params.activityType === "positionnement";

  /** Hidden div to scroll into */
  const activityScrollTop = useRef(null);

  /** moduleCac selector */
  const { currentModule } = useSelector((state: StoreState) => state.moduleCac);

  /**
   * Handle page navigation
   * @param pageValue
   */
  const scrollToTop = (): void => {
    /** Scroll top when page changes */
    if (activityScrollTop.current) {
      setTimeout(() => {
        (activityScrollTop.current as any).parentNode.scrollTop = 0;
      }, 0);
    }
  };

  /** Is video */
  const isVideo: boolean =
    !!activity?.tags && activity.tags.includes(Type.Video);

  /**
   * Handle close lesson
   */
  const handleCloseLesson = (): void => {
    history.replace({
      pathname: match.url.startsWith("/module-du-mois")
        ? "/module-du-mois"
        : `/module/${currentModule?._id}`,
      state: { fetch: true }
    });
  };

  const handleQuizEnd = (score: number): void => {
    console.log("handleQuizEnd", score);
    setBilanScore(score);
    // fetchData();
  };

  /**
   * Increment size text
   */
  const incrementSizeText = (): void => {
    setFontSize(fontSize + 1);
  };

  /**
   * Decrement size text
   */
  const decrementSizeText = (): void => {
    setFontSize(fontSize - 1);
  };

  /**
   * Scroll to top between quiz exercises
   */
  const contentScrollTop = (): void => {
    if (contentRef?.current) contentRef.current.scrollTop = 0;
  };

  /** Fetch data */
  const fetchData = async () => {
    const { params } = match;
    setLoading(true);
    getActivity({
      variables: {
        id: params.activityId,
        isQuiz: isStarter || params.activityType === "quiz",
        fetchModule: !currentModule?.recursiveActivities?.some(
          a => a._id === params.activityId
        )
      }
    });
  };

  /**
   * Use effect get activity list
   */
  useEffect(() => {
    if (match.params.activityId) {
      fetchData();
    }
  }, [match.params.activityId]);

  useEffect(() => {
    if (isAndroid) {
      window.scrollTo(0, 1);
    }
  }, []);

  useEffect(() => {
    if (data?.quiz) {
      setQuizGql(data.quiz);
      const lastBookmark = getLastBookmark(
        data.quiz?.bookmark ?? [],
        isStarter
      );

      if (
        lastBookmark &&
        !lastBookmark.current_question_id &&
        lastBookmark.status === BookmarkStatus.Done
      ) {
        setBilanScore(lastBookmark?.score ?? 0);
      } else {
        setBilanScore(null);
      }

      mapAndSaveData(data.quiz);
    } else if (data?.lesson) {
      mapAndSaveData(data.lesson);
      setBilanScore(null);
    }

    //! WTF
    /* else if (!data) {
      dispatch(setErrorToHandleError(true, 404));
    }*/
  }, [data]);

  const mapAndSaveData = (
    data: QuizBaseFieldsFragment | LessonBaseFieldsFragment
  ) => {
    const activityMapped = mapActivity(data);
    setTitle(isStarter ? "Evaluation des connaissances" : activityMapped.title);

    if (activityMapped.module) {
      dispatch(setCurrentModule(activityMapped.module));
    } else if (!activityMapped.module && currentModule) {
      activityMapped.module = currentModule;
    }
    setActivity(activityMapped);
  };

  useEffect(() => {
    if (activity) {
      //! disable condition for make test
      //if (!activity.module || !canAccessActivity(activity, isStarter)) {
      if (!activity.module) {
        dispatch(setErrorToHandleError(true, 401));
      } else {
        const index = activity.module.recursiveActivities.findIndex(
          a => a._id === activity._id
        );

        if (index === undefined || index < 0) {
          dispatch(setErrorToHandleError(true));
        } else {
          dispatch(
            setSelectedPath({
              blocId: activity.module.recursiveActivities[index].blocId,
              themeId: activity.module.recursiveActivities[index].themeId,
              activityId: activity._id
            })
          );

          if (index < activity.module.recursiveActivities.length - 1) {
            setNextActivity(activity.module.recursiveActivities[index + 1]);
          }
          setLoading(false);
        }
      }
    }
  }, [activity]);

  return (
    <Dialog
      fullScreen={true}
      open={true}
      keepMounted={true}
      className={classes.root}
    >
      {!loading && activity ? (
        <React.Fragment>
          <Helmet defer={false}>
            <meta charSet="utf-8" />
            <title>{title} - RF e-Learning CAC</title>
          </Helmet>

          <Hidden smDown={true}>
            <AppBar position="static" className={classes.appBar}>
              <Toolbar>
                <Grid
                  container={true}
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item={true} xs={true}>
                    <img
                      src={logo}
                      className={classes.logo}
                      alt="rf-elearning"
                    />
                  </Grid>
                  <Grid item={true} xs={8} className={classes.headerTitle}>
                    {!isStarter && activity.type && (
                      <Chip
                        size="small"
                        label={formatterTypeActivity(activity.type)}
                        className={classes.chipDomain}
                        style={{
                          backgroundColor: getBackgroundTypeActivity(
                            activity.type
                          )
                        }}
                      />
                    )}
                    <Typography
                      variant="h5"
                      gutterBottom={true}
                      className={classes.headerTitleName}
                    >
                      {title}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={true}>
                    <Grid
                      container={true}
                      alignItems="flex-start"
                      justify="flex-end"
                      direction="row"
                    >
                      {!isStarter && (
                        <Button
                          variant="outlined"
                          className={classes.button}
                          onClick={() => handleCloseLesson()}
                        >
                          Quitter l'activité
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Hidden>

          <Hidden mdUp={true}>
            <AppBar position="static" className={classes.appBar}>
              <Grid container={true} direction="column">
                <Toolbar className={classes.headerSecondToolbar}>
                  <Grid container={true} alignItems="center">
                    <Grid item={true} xs={1}>
                      <img
                        src={icClose}
                        alt="close"
                        onClick={() => history.goBack()}
                      />
                    </Grid>
                    <Grid item={true} xs={11} className={classes.headerTitle}>
                      {!isStarter && activity.type && (
                        <Chip
                          size="small"
                          label={formatterTypeActivity(activity.type)}
                          className={classes.chipDomain}
                          style={{
                            backgroundColor: getBackgroundTypeActivity(
                              activity.type
                            )
                          }}
                        />
                      )}
                      <Scrollbars style={{ height: 40 }}>
                        <Typography
                          variant="h6"
                          gutterBottom={true}
                          className={classes.headerTitleResponsive}
                        >
                          {title}
                        </Typography>
                      </Scrollbars>
                    </Grid>
                  </Grid>
                </Toolbar>
                {isLesson(activity) && (
                  <Grid
                    container={true}
                    direction="row"
                    alignItems="baseline"
                    className={classes.secondToolbar}
                  >
                    {!currentModule?.expired && (
                      <Grid item={true} xs={9}>
                        <Timer
                          initialTime={activity.confirmedTime}
                          activityTime={activity.estimatedTime ?? 0}
                          isResponsive={true}
                          canPlayTimer={playTimer}
                        />
                      </Grid>
                    )}
                    {!isVideo && (
                      <Grid item={true} xs={3}>
                        <Box m={1}>
                          <Grid
                            container={true}
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                          >
                            <ButtonGroup
                              size="medium"
                              variant="outlined"
                              className={classes.btgroup}
                            >
                              <Button
                                className={classes.textDown}
                                onClick={decrementSizeText}
                              >
                                A
                              </Button>
                              <Button
                                className={classes.textUp}
                                onClick={incrementSizeText}
                              >
                                A
                              </Button>
                            </ButtonGroup>
                          </Grid>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            </AppBar>
          </Hidden>

          <DialogContent ref={contentRef} className={classes.activityContent}>
            <div ref={activityScrollTop} />

            <Hidden smDown={true}>
              {isLesson(activity) && (
                <Grid
                  container={true}
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item={true} xs={true} />
                  <Grid item={true} xs={8} className={classes.headerTitle}>
                    <Grid
                      container={true}
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      {!isVideo && (
                        <ButtonGroup
                          size="medium"
                          variant="outlined"
                          className={classes.btgroup}
                        >
                          <Button
                            className={classes.textDown}
                            onClick={decrementSizeText}
                          >
                            A
                          </Button>
                          <Button
                            className={classes.textUp}
                            onClick={incrementSizeText}
                          >
                            A
                          </Button>
                        </ButtonGroup>
                      )}
                      {!currentModule?.expired && (
                        <Timer
                          initialTime={activity.confirmedTime}
                          activityTime={activity.estimatedTime ?? 0}
                          isResponsive={false}
                          canPlayTimer={playTimer}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid item={true} xs={true} />
                </Grid>
              )}
            </Hidden>

            <Grid
              container={true}
              spacing={3}
              direction="column"
              justify={isVideo ? "flex-start" : undefined}
              alignItems="center"
              className={`${classes.container} ${isVideo &&
                classes.containerVideo}`}
            >
              {isLesson(activity) && (
                <Lesson
                  lesson={activity as LessonCAC}
                  isExpired={!!currentModule?.expired}
                  fontSize={fontSize}
                  scrollToTop={scrollToTop}
                  nextActivity={nextActivity}
                  playTimer={setPlayTimer}
                />
              )}
              {isQuiz(activity) && bilanScore !== null && (
                <Bilan
                  handleClose={() => handleCloseLesson()}
                  handleRestart={() => setBilanScore(null)}
                  quiz={quizGql as any}
                  isStarter={isStarter}
                  score={bilanScore}
                  nextActivity={nextActivity}
                />
              )}
              {isQuiz(activity) && bilanScore === null && (
                <QuizPlayer
                  data={quizGql as any}
                  isStarter={isStarter}
                  isExpired={!!currentModule?.expired}
                  isDone={activity.isDone}
                  onQuizEnd={handleQuizEnd}
                  onExerciseChange={contentScrollTop}
                />
              )}
            </Grid>
          </DialogContent>
        </React.Fragment>
      ) : (
        <SkeletonLoader
          height={880}
          loader={CONST_LOADER.ACTIVITY_LESSON_OR_QUIZZ}
        />
      )}
    </Dialog>
  );
};

export default withRouter(React.memo(ActivityLessonOrQuiz));
