// React
import React, { useEffect, useState } from "react";
// Material
import { Box, Paper, Theme, Typography } from "@material-ui/core";
// Styles
import { createStyles, makeStyles } from "@material-ui/styles";
import { colorCAC } from "../../../styles/color";
import { handleBilanColor } from "../../../helpers/color-translator.helper";
// Components
import NextActivity from "../../NextActivity/NextActivity";
import QuizAnswerProgress from "./QuizAnswerProgress/QuizAnswerProgress";
// Model
import { Quiz, QuizProgress } from "../../../graphql";
import { isFinalQuiz } from "../../../helpers/activity.helper";
import BilanThemeCard from "./BilanThemeCard/BilanThemeCard";
import { getLastBookmark } from "../../../services/activity.service";
import { ActivityPath } from "../../../models/activity.model";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      paddingBottom: "60px",
      width: "100%"
    },
    bilanHeaderTitle: {
      fontSize: 18,
      lineHeight: 1.5,
      paddingTop: 12,
      marginTop: -25,
      zIndex: 3,
      backgroundColor: colorCAC.grayLight,
      fontWeight: "bold",
      position: "relative"
    },
    bilanStartedValidatedScoreTitle: {
      fontSize: 14,
      color: "#828999",
      fontWeight: "bold",
      marginTop: 0
    },
    themeLabel: {
      fontWeight: "bold",
      display: "inline-block",
      marginRight: 15,
      [theme.breakpoints.down("sm")]: {
        display: "block"
      }
    },
    iconProblem: {
      marginBottom: theme.spacing(5),
      width: 223,
      height: 60,
      objectFit: "contain"
    }
  })
);

const formatCorrectAnswerPercentage = (
  validatedScore: number,
  maximalScore: number
): number =>
  maximalScore === 0
    ? 0
    : parseInt(((100 * validatedScore) / maximalScore).toFixed(0));

/**
 * Bilan props
 */
interface IBilanProps {
  quiz: Quiz;
  handleClose: () => void;
  handleRestart: () => void;
  isStarter: boolean;
  score: number | null;
  nextActivity?: ActivityPath;
}

/**
 * Bilan Component
 */
const Bilan = ({
  quiz,
  handleClose,
  handleRestart,
  isStarter,
  score,
  nextActivity
}: IBilanProps) => {
  /** classes */
  const classes = useStyles();
  const [scorePercentage, setScorePercentage] = useState<number>(0);
  const [diffWithStarterQuiz, setDiffWithStarterQuiz] = useState<number>(0);

  useEffect(() => {
    const lastStarterQuizProgress = getLastBookmark(quiz?.bookmark ?? [], true);
    console.log("lastStarterQuizProgress", lastStarterQuizProgress);
    setScorePercentage(
      formatCorrectAnswerPercentage(score ?? 0, quiz.maximalScore)
    );
    setDiffWithStarterQuiz(
      formatCorrectAnswerPercentage(
        (score ?? 0) - (lastStarterQuizProgress?.score ?? 0),
        quiz.maximalScore
      )
    );
  }, [quiz, score]);

  return (
    <Box className={classes.container}>
      <QuizAnswerProgress progress={+scorePercentage} />
      <Typography
        component="p"
        align="center"
        className={classes.bilanHeaderTitle}
        style={{
          color: handleBilanColor(+scorePercentage)
        }}
      >
        Vous avez eu {scorePercentage}% de bonnes réponses
      </Typography>
      {isFinalQuiz(quiz) && (
        <>
          {!isStarter && (
            <Typography
              component="p"
              align="center"
              className={classes.bilanStartedValidatedScoreTitle}
            >
              {diffWithStarterQuiz > 0 && "+"}
              {diffWithStarterQuiz}% par rapport au test de positionnement
            </Typography>
          )}
          <div style={{ paddingBottom: 30, marginTop: 30 }}>
            {quiz.exerciseListData?.length &&
              quiz.exerciseListData.map((exercise, index) => (
                <BilanThemeCard
                  key={index}
                  index={index}
                  exercise={exercise}
                  bookmarks={
                    quiz.bookmark?.filter(
                      (bookmark): bookmark is QuizProgress =>
                        bookmark?.current_question_id === exercise?.learningId
                    ) ?? []
                  }
                />
              ))}
          </div>
        </>
      )}
      <Paper
        elevation={3}
        style={{
          position:
            navigator.platform === "iPad" || "iPhone" ? "fixed" : "absolute",
          bottom: 0,
          height: "70px",
          left: 0,
          right: 0
        }}
      >
        <NextActivity
          handleCloseLesson={isStarter ? handleClose : undefined}
          retry={!isStarter ? handleRestart : undefined}
          nextActivity={nextActivity}
        />
      </Paper>
    </Box>
  );
};

export default React.memo(Bilan);
