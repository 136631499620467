// React
import React, { useState } from "react";
// Material
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Hidden,
  ListItemIcon,
  Typography
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// Models
import { Module } from "../../models/module.model";
// Helpers
import { getSecondsFormatted } from "../../helpers/date-fomatter.helper";
import { getFormattedModuleDescription } from "../../helpers/text-formatter.helper";
// Images
import iconHourGlass from "../../assets/ic-hourglass.svg";
import iconTime from "../../assets/ic-timer.svg";
import iconPdf from "../../assets/ic-pdf.svg";
// Colors
import { colorCAC } from "../../styles/color";
// Components
import LastModuleBlocs from "../LastModuleBlocs/LastModuleBlocs";
import moment from "moment";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: "100%"
    },
    content: {
      height: "100%"
    },
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: theme.spacing(0.5, 0.5),
      wordWrap: "break-word",
      backgroundColor: colorCAC.grayCard,
      [theme.breakpoints.up("md")]: {
        borderLeft: "4px outset",
        borderLeftColor: colorCAC.greenDark
      },
      [theme.breakpoints.down("sm")]: {
        borderTop: "4px outset",
        borderTopColor: colorCAC.greenDark
      }
    },
    title: {
      color: "#FFFFFF",
      backgroundColor: colorCAC.greenDark,
      paddingLeft: 3,
      paddingRight: 3
    },
    labelLight: {
      fontSize: 14
    },
    moduleDescription: {
      whiteSpace: "pre-wrap",
      [theme.breakpoints.down("md")]: {
        textAlign: "justify"
      }
    },
    showMoreButton: {
      fontWeight: 800,
      textTransform: "none",
      color: colorCAC.black,
      display: "block",
      "&:hover": {
        textDecoration: "underline"
      }
    },
    downloadContainer: {
      cursor: "pointer",
      [theme.breakpoints.up("md")]: {
        position: "absolute",
        width: "auto",
        right: 0,
        top: 40
      }
    },
    pdfIcIcon: {
      height: 26
    },
    downloadLabel: {
      textAlign: "center",
      fontSize: 14,
      [theme.breakpoints.down("md")]: {
        textDecoration: "underline"
      },
      [theme.breakpoints.down("md")]: {
        "&:hover": {
          textDecoration: "underline"
        }
      }
    },
    list: {
      maxWidth: "900px",
      padding: "10px",
      "& > ul ": {
        margin: "0px",
        display: "flex",
        flexDirection: "column",
        width: "90%"
      },
      "& > p": {
        margin: 0,
        wordWrap: "break-word"
      }
    },
    time: {
      color: theme.palette.primary.main,
      margin: theme.spacing(1)
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary
    },
    cardBloc: {
      width: 208,
      height: 180
    },
    actions: {
      padding: 16
    },
    times: {
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      }
    },
    blocs: {
      [theme.breakpoints.up("md")]: {
        alignItems: "center"
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      }
    }
  })
);

/**
 * LastModuleCard props
 */
interface ILastModuleCardProps {
  module: Module;
}

/**
 * LastModuleCard component
 */
const LastModuleCard = (props: ILastModuleCardProps) => {
  /** Classes  */
  const classes = useStyles();
  const { module } = props;

  /** Show more module description state */
  const [showMore, setShowMore] = useState(false);

  /** Show more limit line */
  const limitLines: number = 3;

  /**
   * Determine if we have to show a "Show more" button for module's description
   */
  const shouldDisplayShowMoreButton = (): boolean => {
    return !!(
      module.description &&
      module.description.split(/<\/li>|<\/p>/).length > limitLines
    );
  };

  return (
    <React.Fragment>
      <Grid container={true} alignItems={"center"} className={classes.wrapper}>
        <Grid item={true} xs={true} className={classes.content}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container={true} style={{ position: "relative" }}>
                <Grid item={true}>
                  <Box
                    marginBottom={1}
                    marginTop={1}
                    fontWeight="600"
                    color="textPrimary"
                    fontSize="h6.fontSize"
                    className={classes.title}
                  >
                    MODULE {module.title}
                  </Box>
                </Grid>
                {module.validityTimeRange && (
                  <Hidden smDown={true}>
                    <Grid
                      container={true}
                      item={true}
                      xs={true}
                      alignItems={"center"}
                      justify={"flex-end"}
                    >
                      <Typography
                        noWrap={true}
                        className={classes.labelLight}
                        color="textSecondary"
                      >
                        Mise en ligne :{" "}
                        {moment(module.validityTimeRange.start).format("L")}
                      </Typography>
                    </Grid>
                  </Hidden>
                )}

                <Grid item={true} xs={12}>
                  <Box
                    marginBottom={1}
                    marginTop={1}
                    fontWeight="600"
                    color="textPrimary"
                    fontSize="h6.fontSize"
                  >
                    Principaux thèmes traités :
                  </Box>
                </Grid>
                <Grid item={true} xs={12}>
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    component="div"
                    gutterBottom={true}
                    className={classes.moduleDescription}
                  >
                    <React.Fragment>
                      {
                        <div
                          className={classes.list}
                          dangerouslySetInnerHTML={{
                            __html: getFormattedModuleDescription(
                              module.description ?? "",
                              showMore ? 10 : limitLines,
                              false
                            )
                          }}
                        />
                      }
                      {shouldDisplayShowMoreButton() && (
                        <Button
                          className={classes.showMoreButton}
                          onClick={() => setShowMore(!showMore)}
                        >
                          {showMore ? "Voir moins" : "Tout voir"}
                        </Button>
                      )}
                    </React.Fragment>
                  </Typography>
                </Grid>

                {module.file && (
                  <Grid
                    container={true}
                    justify="flex-start"
                    alignItems="center"
                    className={classes.downloadContainer}
                    onClick={() => window.open(module.file, "_blank")}
                  >
                    <Grid item={true} xs={"auto"} style={{ display: "flex" }}>
                      <ListItemIcon
                        style={{ marginRight: 5, minWidth: "auto" }}
                      >
                        <img
                          src={iconPdf}
                          alt="icon-attestation"
                          className={classes.pdfIcIcon}
                        />
                      </ListItemIcon>
                    </Grid>
                    <Grid item={true}>
                      <Typography
                        className={classes.downloadLabel}
                        color="textPrimary"
                        gutterBottom={false}
                      >
                        Télécharger le sommaire
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <CardActions className={classes.actions}>
              <Grid container={true} direction="column" spacing={1}>
                <Grid item={true} xs={true}>
                  <Grid
                    container={true}
                    justify="flex-start"
                    className={classes.times}
                  >
                    <Grid item={true} xs={true}>
                      <Grid
                        container={true}
                        justify="flex-start"
                        alignContent="center"
                        spacing={1}
                        direction="row"
                      >
                        <Grid item={true}>
                          <img src={iconTime} alt="temps estimé" />
                        </Grid>
                        <Grid item={true}>
                          <Typography
                            className={classes.labelLight}
                            color="textSecondary"
                            gutterBottom={true}
                            noWrap={true}
                          >
                            Temps du module
                          </Typography>
                        </Grid>
                        <Grid item={true}>
                          <Box
                            className={classes.labelLight}
                            color="textPrimary"
                            component="span"
                            fontWeight="600"
                          >
                            {getSecondsFormatted(module.estimatedTime)}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    {module.validityTimeRange && (
                      <Grid item={true} xs={true}>
                        <Grid
                          container={true}
                          justify="flex-start"
                          alignContent="center"
                          spacing={1}
                          direction="row"
                        >
                          <Grid item={true}>
                            <img src={iconHourGlass} alt="temps passé" />
                          </Grid>
                          <Grid item={true}>
                            <Typography
                              className={classes.labelLight}
                              color="textSecondary"
                              gutterBottom={true}
                            >
                              Fin de validité
                            </Typography>
                          </Grid>
                          <Grid item={true}>
                            <Box
                              className={classes.labelLight}
                              color="textPrimary"
                              component="span"
                              fontWeight="600"
                            >
                              {moment(module.validityTimeRange.end).format("L")}
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Hidden smDown={true}>
                  <LastModuleBlocs module={module} />
                </Hidden>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default React.memo(LastModuleCard);
