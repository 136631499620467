/** Constant */
import { CONST } from "../config/constant";
import { Category, ModuleFieldsFragment, ValidityTimeRange } from "../graphql";
import { Activity } from "../models/activity.model";

/** Models */
import {
  Access,
  Setting,
  SubscriptionPeriod,
  User
} from "../models/user.model";

/** Store */
import { store } from "../store/createStore";
import { sortModuleByValidity } from "./module.service";

/**
 * Get full name
 */
export const getFullName = (keycloak: any): string =>
  keycloak!.tokenParsed.name || "";

/**
 * Get user id
 */
export const getUserId = (): string => store.getState().user!._id;

/**
 * Is admin
 */
export const getIsAdmin = (): boolean => !!store.getState().user?.isAdmin;

/**
 * Is super admin
 */
export const getIsSuperAdmin = (): boolean =>
  !!store.getState().user?.isSuperAdmin;

/**
 * Disconnect user
 */
export const disconnectUser = (keycloak: any) => {
  keycloak && keycloak.logout();
};

/**
 * Get subscription periods
 */
export const getSubscriptionPeriods = (): SubscriptionPeriod[] =>
  store.getState().user?.subscription?.periods ?? [];

/**
 * Get most recent module to display
 */
export const getMostRecentModule = (): string =>
  store.getState().user?.access?.mostRecentModule ?? "";

/**
 * Get user survey setting
 */
export const getSurveySetting = (): Setting | undefined =>
  store.getState().user?.survey;

/**
 * Get user's informations
 */
export const getUser = async (
  fetchWithCredentials: typeof fetch
): Promise<User> => {
  /** URL user information from Alice */
  const response = await fetchWithCredentials(
    `${CONST.ALICE_ADAPTER_ENDPOINT}/users/me`,
    {
      method: "get"
    }
  );

  if (response.status !== 200) {
    throw new Error();
  }
  return await response.json();
};

/**
 * Get available module ids for user
 */
export const getModulesAccess = (
  categories: (ModuleFieldsFragment | null)[]
): Access => {
  const access = {
    modules: {} as Record<string, boolean>,
    mostRecentModule: ""
  };
  const subscriptionPeriods = getSubscriptionPeriods();
  let isBonusAdded = false;

  let modules: Category[] = categories.filter(c => !!c) as Category[];
  modules = modules.sort((a, b) => sortModuleByValidity(a, b, -1));

  for (let i = 0; i < modules.length; i++) {
    if (!modules[i] || !modules[i].validityTimeRange) {
      // If module is null or without validity range
      continue;
    } else if (
      subscriptionPeriods.some(s =>
        checkModuleSubscriptionPeriod(s, modules[i].validityTimeRange!)
      )
    ) {
      // If module is in subscription period
      access.modules[modules[i]._id] = true;
      if (!access.mostRecentModule) {
        access.mostRecentModule = modules[i]._id;
      }
      isBonusAdded = false;
    } else if (!isBonusAdded && i > 0 && access.modules[modules[i - 1]._id]) {
      // If module not in subscription but last module is, add bonus module
      access.modules[modules[i]._id] = true;
      if (!access.mostRecentModule) {
        access.mostRecentModule = modules[i]._id;
      }
      isBonusAdded = true;
    } else {
      isBonusAdded = false;
    }
  }

  return access;
};

const checkModuleSubscriptionPeriod = (
  subscriptionPeriod: SubscriptionPeriod,
  validity: ValidityTimeRange
) => {
  const validityStart = new Date(validity.start);
  const periodStart = new Date(subscriptionPeriod.start);
  const periodEnd = new Date(subscriptionPeriod.end);

  if (validityStart >= periodStart && validityStart <= periodEnd) {
    return true;
  } else if (
    validityStart.getFullYear() === periodStart.getFullYear() &&
    validityStart.getMonth() === periodStart.getMonth()
  ) {
    return true;
  }
  return false;
};

/**
 * check if module available
 */
export const canAccessModule = (_id: string): boolean =>
  !!store.getState().user?.access?.modules[_id];

/**
 * check if activity available
 */
export const canAccessActivity = (
  activity: Activity,
  isStarter: boolean
): boolean => {
  if (!activity.module || !canAccessModule(activity.module._id)) {
    return false;
  }

  const bloc = activity.module.blocs.find(b =>
    b.recursiveActivities.find(a => a._id === activity._id)
  );
  if (!bloc || (!activity.isStarted && !isStarter && !!bloc.starterQuiz)) {
    return false;
  }

  return true;
};

/**
 * Patch notification setting
 */
export const patchNotificationSetting = async (
  fetchCredentials: typeof fetch,
  value: boolean
) => {
  return patchSetting(fetchCredentials, "notificationCac", value);
};

/**
 * Patch survey setting
 */
export const patchSurveySetting = async (
  fetchCredentials: typeof fetch,
  value: boolean
) => {
  return patchSetting(fetchCredentials, "survey", value);
};

/**
 * Patch user setting
 */
export const patchSetting = async (
  fetchCredentials: typeof fetch,
  field: string,
  value: boolean
) => {
  await fetchCredentials(
    `${CONST.ALICE_ADAPTER_ENDPOINT}/users/me/setting/${field}`,
    {
      method: "PATCH",
      body: JSON.stringify({ value: value }),
      headers: {
        "content-type": "application/json"
      }
    }
  );
};
